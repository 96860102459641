

jQuery(document).ready(function() {

	jQuery("#block-menu-menu-catalog .content").hide();
	jQuery("#block-multiblock-1 .content").hide();

	jQuery("#block-menu-menu-catalog").hover(function(){
		jQuery("#block-menu-menu-catalog .content").fadeIn(350);
	}, function() {
		jQuery("#block-menu-menu-catalog .content").fadeOut(350);
	});

	jQuery("#block-multiblock-1").hover(function(){
		jQuery("#block-multiblock-1 .content").fadeIn(350);
	}, function() {
		jQuery("#block-multiblock-1 .content").fadeOut(350);
	});

	/*jQuery(document).click(function(){
		if (jQuery("#showCart").hasClass("visible")) {
			jQuery("#showCart").removeClass("visible");
			jQuery(".ajax-shopping-cart-wrapper").hide();
		}
	})

	jQuery("#showCart").click(function(event){
		if (jQuery(this).hasClass("visible")) {
			jQuery(".ajax-shopping-cart-wrapper").fadeOut(450);
			jQuery(this).removeClass("visible");
		} else {
			jQuery(".ajax-shopping-cart-wrapper").fadeIn(450);
			jQuery(this).addClass("visible");
			event.stopPropagation();
		}
	})*/


	jQuery(window).scroll(function () {
				if (jQuery(this).scrollTop() > 100) {
						jQuery('.totop').fadeIn();
				} else {
						jQuery('.totop').fadeOut();
				}
		});

		jQuery('.totop').click(function () {
				jQuery("html, body").animate({
						scrollTop: 0
				}, 600);
				return false;
		});
});